import React, { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import * as btnStyles from 'components/Button/button.module.scss'
import * as cls from './contactForm.module.scss'

const ContactsForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const defaultDataState = {
    name: '',
    email: '',
    subject: '',
    content: '',
  }
  const [data, setData] = useState({ ...defaultDataState })
  const [formSubmittedSuccess, setFormSubmittedSuccess] = useState(false)

  if (!executeRecaptcha) {
    return
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    const recaptchaToken = await executeRecaptcha('contact')

    fetch(process.env.GATSBY_FORM_SUBMIT_URL, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ formData: data, token: recaptchaToken }),
    }).then(() => {
      setFormSubmittedSuccess(true)
      setTimeout(() => {
        setFormSubmittedSuccess(false)
      }, 5000)
    }).finally(() => {
      setData({ ...defaultDataState })
    })
  }

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    setData({
      ...data,
      [name]: value,
    })
  }

  const { name, email, subject, content } = data;
  const enabled = name && email && subject && content;

  return (
    <form className={`${cls.form} column is-half`} onSubmit={onSubmit}>
      <div className={`${cls.form__success} ${formSubmittedSuccess ? cls.form__successShow : null}`}>
        Your message has been successfully sent.
      </div>
      <div className={cls.form__caption}>
        <h2 className={cls.form__title}>
          Nachricht
        </h2>
        <p className={cls.form__text}>
          (*) notwendige Informationen
        </p>
      </div>

      <div className="field">
        <div className="control">
          <input
            className="input"
            placeholder="Vorname *"
            name="name"
            value={data.name}
            onChange={handleInputChange}
            required
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <input
            className="input"
            type="email"
            placeholder="Email *"
            name="email"
            value={data.email}
            onChange={handleInputChange}
            required
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <input
            className="input"
            placeholder="Betreff *"
            name="subject"
            value={data.subject}
            onChange={handleInputChange}
            required
          />
        </div>
      </div>

      <div className="control">
        <textarea
          className={`textarea ${cls.form__textarea}`}
          placeholder="Nachricht schreiben *"
          style={{ resize: 'none' }}
          name="content"
          value={data.content}
          onChange={handleInputChange}
          required
        />
      </div>

      <button
        className={`${btnStyles.btn} ${cls.form__submit}`}
        type="submit"
        style={{ width: '100%' }}
        disabled={!enabled}
      >
        Abschicken
      </button>

    </form>
  )
}

export default ContactsForm
