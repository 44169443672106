import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'

import { Header } from 'components/Header'
import Seo from 'components/seo'
import ContactsForm from './components/ContactForm/ContactForm'

import * as cls from './contact.module.scss'

const ContactsPage = ({ pageContext: { pageData } }) => {
  const center = React.useMemo(() => { return { lat: +pageData.latitude, lng: +pageData.longitude } }, [pageData.latitude, pageData.longitude])
  const { isLoaded } = useJsApiLoader({
    id: 'google-map',
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_KEY,
  })

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setTimeout(() => {
      map.setCenter(center)
      map.setZoom(17)
    }, 300)
  }, [center])

  return (
    <section className={cls.contacts}>

      <Seo title={pageData.title} meta={pageData.seo} />
      <Header
        h1={pageData.header.h1}
        breadcrumbs={[{
          title: pageData.title,
        }]}
        isWide
      />

      <div className="container">
        <div className={`${cls.contacts__body} columns`}>

          <ContactsForm />

          <section className={`${cls.contacts__map} column`}>
            <h3 className={cls.contacts__map_title}>
              Anschrift
            </h3>

            <p className={cls.contacts__map_text}>
              {pageData.address}
            </p>

              {
                isLoaded &&
                  <GoogleMap
                    mapContainerClassName={cls.contacts__map_graph}
                    onLoad={onLoad}
                  >
                    <Marker position={center} />
                  </GoogleMap>
              }

            <h3 className={cls.contacts__map_title}>
              Email
            </h3>

            <p className={cls.contacts__map_text}>
              <a className={cls.contacts__map_link} href={`mailto:${pageData.email}`}>
                {pageData.email}
              </a>
            </p>
          </section>
        </div>
      </div>
    </section>
  )
}

export default ContactsPage
